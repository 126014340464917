import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import axios from 'axios';
import { ethers, utils } from 'ethers'

import stakeIcon from '../../assets/images/stake.png';
import unstakeIcon from '../../assets/images/unstake.png';


import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';


import { useSelector } from 'react-redux';
import './Mint.scss';
import {mintEnabled, setweb3provider} from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'
import Header from '../Header/Header'

import okWeb3 from '@okwallet/extension';

const { CHAINS } = okWeb3;

const alphaAbi = require('../../helper/alpha.json')
const stakeAbi = require('../../helper/staking.json')

export default function Staking() {
  
  const address = useSelector(state => state.address);
  const isOkx = useSelector(state => state.isOkx);
  const web3provider = useSelector(state => state.web3Provider);
  const [mintVisible, setMintVisible] = React.useState(true);
  const [amount, setAmount] = React.useState(0);
  const [supply, setSupply] = React.useState(0);

  const [alphaBalance, setAlphaBalance] = React.useState(0)
  const [rawAlphaBalance, setRawAlphaBalance] = React.useState(0)
  const [rawStakeBalance, setRawStakeBalance] = React.useState(0)
  const [stakeBalance, setStakeBalance] = React.useState(0)
  const [earnedBalance, setEarnedBalance] = React.useState(0)
  const [totalRewardBalance, setTotalRewardBalance] = React.useState(0)

  const [stakeAmount, setStakeAmount] = React.useState(0)
  const [unstakeAmount, setUnstakeAmount] = React.useState(0)
  const [showModal, setShowModal] = React.useState(false);

  const [stakeMax, setStakeMax] = React.useState(0)

  const [showUnstakeModal, setShowUnstakeModal] = React.useState(false);
  const [showOldUnstakeModal, setShowOldUnstakeModal] = React.useState(false);

  const contractAddress = process.env.GATSBY_STAKING_CONTRACT_ADDRESS
  const tokenAddress = process.env.GATSBY_ALPHA_CONTRACT_ADDRESS

  const stakingOldAddress = process.env.GATSBY_STAKING_OLD_CONTRACT_ADDRESS

  const currentPoolId = 2;

  const [oldStakeBalance, setOldStakeBalance] = React.useState(0)

  const [oldRawStakeBalance, setOldRawStakeBalance] = React.useState(0)

  const [oldEarnedBalance, setOldEarnedBalance] = React.useState(0)

  const [oldStakeBalance2, setOldStakeBalance2] = React.useState(0)

  const [oldRawStakeBalance2, setOldRawStakeBalance2] = React.useState(0)

  const [oldEarnedBalance2, setOldEarnedBalance2] = React.useState(0)

  const [oldStakeBalance3, setOldStakeBalance3] = React.useState(0)

  const [oldRawStakeBalance3, setOldRawStakeBalance3] = React.useState(0)

  const [oldEarnedBalance3, setOldEarnedBalance3] = React.useState(0)

  const [unstakePoolId, setUnstakePoolId] = React.useState(1)
  

  const openStake = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setShowUnstakeModal(false)
    setShowOldUnstakeModal(false)
  }

  const openUnstake = () => {
    setUnstakePoolId(2)
    setUnstakeAmount(rawStakeBalance)
    setShowUnstakeModal(true)
  }

  const openOldUnstake3 = () => {
    setUnstakePoolId(1)
    setUnstakeAmount(oldRawStakeBalance3)
    setShowUnstakeModal(true)
  }

  const openOldUnstake2 = () => {
    setUnstakePoolId(0)
    setUnstakeAmount(oldRawStakeBalance2)
    setShowUnstakeModal(true)
  }

  const openOldUnstake = () => {
    setUnstakeAmount(oldRawStakeBalance)
    setShowOldUnstakeModal(true)
  }

  const handleStakeAmountChange = (event) => {
    setStakeAmount(event.target.value);
  }

  const handleUnStakeAmountChange = (event) => {
    setUnstakeAmount(event.target.value);
  }

  const handleStakeConfirm = async (event) => {

    try{
    
      if (stakeAmount > rawAlphaBalance){
        toast.error('Alpha Balance Not Enough');
        return;
      }
    
      const signer = web3provider?.getSigner();
      const tokenContract = new ethers.Contract(tokenAddress, alphaAbi, signer);
      
      const stakeAmountInWei = ethers.utils.parseEther(stakeAmount);
    
      // Check if approval is necessary
      const allowance = await tokenContract.allowance(address, contractAddress);
      if (allowance.lt(stakeAmountInWei)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');
        
        // Approve the Staking Contract to spend the tokens
        const approveTx = await tokenContract.approve(contractAddress, stakeAmountInWei);
        await approveTx.wait();

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }
    
      // Now, create a new contract instance for Staking Contract and call stake function
      const stakingContract = new ethers.Contract(contractAddress, stakeAbi, signer);
      
      // Assuming poolId is defined somewhere or passed as a parameter
      const stakeTx = await stakingContract.stake(stakeAmountInWei, currentPoolId);
      await stakeTx.wait();

      // Staking has been completed successfully, display the toast message
      toast.success('Staking Completed Successfully');
    
      // Update the alpha balance after staking
      await loadToken()
      await loadEarned()
      await loadStaked()
    }catch(err){
      toast.error('Staking Failed')
    }
  }


  const handleStakeConfirmOkx = async (event) => {

    try{
    
      if (stakeAmount > rawAlphaBalance){
        toast.error('Alpha Balance Not Enough');
        return;
      }
    
      const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');
      const tokenContract = new ethers.Contract(tokenAddress, alphaAbi, provider);
      
      const stakeAmountInWei = ethers.utils.parseEther(stakeAmount);
    
      // Check if approval is necessary
      const allowance = await tokenContract.allowance(address, contractAddress);
      if (allowance.lt(stakeAmountInWei)) {
        // Notify user about the approval request
        toast.info('Requesting Approval');

        const iface = new utils.Interface(stakeAbi)
        const data = iface.encodeFunctionData("approve", [contractAddress, stakeAmountInWei]);


        const payload = {
          from: address,
          to: contractAddress,
          data: data,
          value: '0x00',
        };

        const tx = await okWeb3.sendTransaction({chainName: CHAINS.ETHEREUM, payload})

        // Notify user about the successful approval
        toast.success('Approval Successful');
      }
    
      // Now, create a new contract instance for Staking Contract and call stake function
      
      // Assuming poolId is defined somewhere or passed as a parameter

      const iface = new utils.Interface(stakeAbi)
      const data = iface.encodeFunctionData("stake", [stakeAmountInWei, currentPoolId]);


      const payload = {
        from: address,
        to: contractAddress,
        data: data,
        value: '0x00',
      };

      const tx = await okWeb3.sendTransaction({chainName: CHAINS.ETHEREUM, payload})

      // Staking has been completed successfully, display the toast message
      toast.success('Staking Completed Successfully');
    
      // Update the alpha balance after staking
      await loadTokenOkx()
      await loadEarnedOkx()
      await loadStakedOkx()
    }catch(err){
      console.log(err)
      toast.error('Staking Failed')
    }
  }

  const handleStakeChain = async () => {
    if(isOkx){
      await handleStakeConfirmOkx()
    }else{
      await handleStakeConfirm()
    }
  }

  const handleOldUnstake = async () => {
    try{
    const signer = web3provider?.getSigner();
    const stakingContract = new ethers.Contract(stakingOldAddress, stakeAbi, signer);
    
    const unstakeAmountInWei = ethers.utils.parseEther(unstakeAmount.toString());
  
    const unstakeTx = await stakingContract.unstake(unstakeAmountInWei, 1);
    await unstakeTx.wait();

    // Staking has been completed successfully, display the toast message
    toast.success('Unstake Completed Successfully');
  
    await loadEarned()
    await loadOldStaked()
    }catch(err){
      toast.error('Unstake FAILED')
    }
  }

  const handleUnstake = async () => {
    try{
    const signer = web3provider?.getSigner();
    const stakingContract = new ethers.Contract(contractAddress, stakeAbi, signer);
    
    const unstakeAmountInWei = ethers.utils.parseEther(unstakeAmount.toString());
  
    const unstakeTx = await stakingContract.unstake(unstakeAmountInWei, unstakePoolId);
    await unstakeTx.wait();

    // Staking has been completed successfully, display the toast message
    toast.success('Unstake Completed Successfully');

    await loadToken()
    await loadEarned()
    await loadStaked()
    }catch(err){
      toast.error('Unstake FAILED')
    }
  }

  const handleUnstakeOkx = async () => {
    try{


      const unstakeAmountInWei = ethers.utils.parseEther(unstakeAmount.toString());

      const iface = new utils.Interface(stakeAbi)
      const data = iface.encodeFunctionData("unstake", [unstakeAmountInWei, currentPoolId]);


      const payload = {
        from: address,
        to: contractAddress,
        data: data,
        value: '0x00',
      };

      const tx = await okWeb3.sendTransaction({chainName: CHAINS.ETHEREUM, payload})

      // Staking has been completed successfully, display the toast message
      toast.success('Unstake Completed Successfully');
    
      await loadToken()
      await loadEarned()
      await loadStaked()
    }catch(err){
      console.log(err)
      toast.error('Unstake FAILED')
    }
  }


  const handleClaim = async () => {
    if(isOkx){
      await handleClaimRewardsOkx()
    }else{
      await handleClaimRewards()
    }
  }

  const handleUnstakeChain = async () => {
    if(isOkx){
      await handleUnstakeOkx()
    } else{
      await handleUnstake()
    }
  }
  
  const handleClaimRewards = async () => {
    try{
    const signer = web3provider?.getSigner();
    const stakingContract = new ethers.Contract(contractAddress, stakeAbi, signer);
    
    const claimTx = await stakingContract.claimRewards(currentPoolId);
    await claimTx.wait();

    // Staking has been completed successfully, display the toast message
    toast.success('Reward Claimed Successfully');
  

    await loadToken()
    await loadEarned()
    await loadStaked()
    }catch(err){
      console.log(err)
      toast.error('Claiming FAILED')
    }
  }

  const handleClaimRewardsOkx = async () => {
    try{
        const iface = new utils.Interface(stakeAbi)
        const data = iface.encodeFunctionData("claimRewards", [currentPoolId]);


        const payload = {
          from: address,
          to: contractAddress,
          data: data,
          value: '0x00',
        };

        const tx = await okWeb3.sendTransaction({chainName: CHAINS.ETHEREUM, payload})

        // Staking has been completed successfully, display the toast message
        toast.success('Reward Claimed Successfully');
      

        await loadTokenOkx()
        await loadEarnedOkx()
        await loadStakedOkx()
      }catch(err){
        console.log(err)
        toast.error('Claiming FAILED')
      }
  }

  useEffect(async() => {
    //Runs only on the first render
    if(isOkx){
      await loadTokenOkx();
      await loadEarnedOkx();
      await loadStakedOkx();
    }else{
    }
  }, [isOkx]);

  useEffect(async() => {
    //Runs only on the first render
    console.log("called")
    if(web3provider){
      console.log(web3provider)
      await loadToken();
      await loadEarned();
      await loadStaked();
      await loadOldEarned();
      await loadOldStaked();
      await loadOldStaked2();
      await loadOldEarned2();
      await loadOldStaked3();
      await loadOldEarned3();
    }else{
    }
  }, [web3provider]);


  const loadToken = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(tokenAddress, alphaAbi, signer);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.ceil(balanceInEther) - 1;
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});
    
    setAlphaBalance(formattedBalance);
    setStakeAmount(String(roundedBalanceInEther));
    setStakeMax(String(roundedBalanceInEther));
    setRawAlphaBalance(String(roundedBalanceInEther));
  }

  const loadTokenOkx = async() => {
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');

    const contract = new ethers.Contract(tokenAddress, alphaAbi, provider);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    let roundedBalanceInEther = 0;
    if(balanceInEther > 0){
      roundedBalanceInEther = Math.ceil(balanceInEther) - 1;
    }
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});
    
    setAlphaBalance(formattedBalance);
    setStakeAmount(String(roundedBalanceInEther));
    setStakeMax(String(roundedBalanceInEther));
    setRawAlphaBalance(String(roundedBalanceInEther));
    
  }

  const loadOldEarned = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(stakingOldAddress, stakeAbi, signer);
    const stakeBalance = await contract.earned(address,1)

    const totalEarned = await contract.getTotalRewardsClaimed(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setOldEarnedBalance(formattedBalance);
  }

  const loadOldEarned3 = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.earned(address,1)

    const totalEarned = await contract.getTotalRewardsClaimed(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setOldEarnedBalance3(formattedBalance);
  }

  const loadOldEarned2 = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.earned(address,0)

    const totalEarned = await contract.getTotalRewardsClaimed(address,0)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setOldEarnedBalance2(formattedBalance);
  }

  const loadEarned = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.earned(address,currentPoolId)

    const totalEarned = await contract.getTotalRewardsClaimed(address,currentPoolId)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setEarnedBalance(formattedBalance);
    setTotalRewardBalance(formattedTotalEarned)
  }

  const loadEarnedOkx = async() => {
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');
    const contract = new ethers.Contract(contractAddress, stakeAbi, provider);
    const stakeBalance = await contract.earned(address,currentPoolId)

    const totalEarned = await contract.getTotalRewardsClaimed(address,currentPoolId)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);
    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});

    const totalEarnedInEther = ethers.utils.formatEther(totalEarned);
    const formattedTotalEarned = parseFloat(totalEarnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setEarnedBalance(formattedBalance);
    setTotalRewardBalance(formattedTotalEarned)
  }

  const loadStaked = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.getStaked(address,currentPoolId)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setRawStakeBalance(balanceInEther)
    setUnstakeAmount(balanceInEther)

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setStakeBalance(formattedBalance);
  }
  

  const loadOldStaked = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(stakingOldAddress, stakeAbi, signer);
    const stakeBalance = await contract.getStaked(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setOldRawStakeBalance(balanceInEther)
    //setUnstakeAmount(balanceInEther)

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setOldStakeBalance(formattedBalance);
  }

  const loadOldStaked2 = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.getStaked(address,0)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setOldRawStakeBalance2(balanceInEther)
    //setUnstakeAmount(balanceInEther)

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setOldStakeBalance2(formattedBalance);
  }

  const loadOldStaked3 = async() => {
    const signer = web3provider?.getSigner();
    const contract = new ethers.Contract(contractAddress, stakeAbi, signer);
    const stakeBalance = await contract.getStaked(address,1)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setOldRawStakeBalance3(balanceInEther)
    //setUnstakeAmount(balanceInEther)

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setOldStakeBalance3(formattedBalance);
  }

  const loadStakedOkx = async() => {
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/72e26738bb554da6b135791a16a368ec');
    const contract = new ethers.Contract(contractAddress, stakeAbi, provider);
    const stakeBalance = await contract.getStaked(address,currentPoolId)
    
    const balanceInEther = ethers.utils.formatEther(stakeBalance);

    setRawStakeBalance(parseFloat(balanceInEther))
    setUnstakeAmount(parseFloat(balanceInEther) )

    const formattedBalance = parseFloat(balanceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:4});
    setStakeBalance(formattedBalance);
  }


  return (
    <>
    <motion.section 
    {...paragraphAnimation}
    id='Staking' className='Staking'>
      { mintVisible &&
      <div className='Mint__content'>
        <div className='flex-container'>
          <div className='flex-staking-left' style={{color:'white'}}>
            
            <div className='wolf-icon'>
                  <img src={stakeIcon}></img>
            </div>

            <div className='wolf-text-container'>
                  WOLF FUND
            </div>

            <div className="staked-text-container">
                  $ALPHA Staked
            </div>

            <div className="staked-counter-container">
                  <b className='staked-counter'>{stakeBalance}</b>
            </div>

            <div className="buyalpha-text-container">
                  BUY $ALPHA
            </div>

            <div className="balancealpha-text-container">
                  AVAILABLE: {alphaBalance} $ALPHA
            </div>

            <button className='button-stake-container' onClick={openStake}>
                  STAKE
            </button>

            <button className='button-unstake-container' onClick={openUnstake}>
                  UNSTAKE
            </button>


          </div>

          <div className='flex-staking-right' style={{color:'white'}}>

            <div className='wolf-icon'>
                  <img src={unstakeIcon}></img>
            </div>

            <div className='wolf-text-container'>
                  ALPHASHARD REWARD
            </div>

            <div className="staked-text-container">
                  Unclaimed Rewards
            </div>

            <div className="claim-counter-container">
                  <b className='claim-counter'>{earnedBalance}</b>
            </div>

            <div className="voidclaim-text-container">
                  &nbsp;
            </div>

            <div className="balancealpha-text-container">
                  Total Reward Claimed: {totalRewardBalance} $SHARDS
            </div>

            <button className='button-claimrewards-container' onClick={handleClaim}>
                  CLAIM REWARDS
            </button>

          </div>
        </div>
        <div className='flex-container-bottom'>
          <div className='flex-staking-left' style={{color:'white'}}>
            
            <div className='wolf-icon'>
                  <img src={stakeIcon}></img>
            </div>

            <div className='wolf-text-container'>
                  OLD WOLF FUND 3
            </div>

            <div className="staked-text-container">
                  $ALPHA Staked
            </div>

            <div className="staked-counter-container">
                  <b className='staked-counter'>{oldStakeBalance3}</b>
            </div>

            <div className="staked-text-container">
                  Unclaimed Rewards
            </div>

            <div className="claim-counter-container">
                  <b className='claim-counter'>{oldEarnedBalance3}</b>
            </div>

            <button className='button-unstake-container' onClick={openOldUnstake3}>
                UNSTAKE
            </button>
          </div>
        </div>
        <div className='flex-container-bottom'>
          <div className='flex-staking-left' style={{color:'white'}}>
            
            <div className='wolf-icon'>
                  <img src={stakeIcon}></img>
            </div>

            <div className='wolf-text-container'>
                  OLD WOLF FUND 2
            </div>

            <div className="staked-text-container">
                  $ALPHA Staked
            </div>

            <div className="staked-counter-container">
                  <b className='staked-counter'>{oldStakeBalance2}</b>
            </div>

            <div className="staked-text-container">
                  Unclaimed Rewards
            </div>

            <div className="claim-counter-container">
                  <b className='claim-counter'>{oldEarnedBalance2}</b>
            </div>

            <button className='button-unstake-container' onClick={openOldUnstake2}>
                UNSTAKE
            </button>
          </div>
        </div>
        <div className='flex-container-bottom'>
          <div className='flex-staking-left' style={{color:'white'}}>
            
            <div className='wolf-icon'>
                  <img src={stakeIcon}></img>
            </div>

            <div className='wolf-text-container'>
                  OLD WOLF FUND 1
            </div>

            <div className="staked-text-container">
                  $ALPHA Staked
            </div>

            <div className="staked-counter-container">
                  <b className='staked-counter'>{oldStakeBalance}</b>
            </div>

            <div className="staked-text-container">
                  Unclaimed Rewards
            </div>

            <div className="claim-counter-container">
                  <b className='claim-counter'>{oldEarnedBalance}</b>
            </div>

            <button className='button-unstake-container' onClick={openOldUnstake}>
                  UNSTAKE
            </button>
          </div>
        </div>
        <footer className="footer">
          <div className="footer__section"> 
            <a href='https://etherscan.io/token/0x38f9bb135ea88033f4377b9ea0fb5cfb773fec2f'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
            <a href='https://discord.com/invite/cGBG3Q9RdB'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
            <a href='https://twitter.com/ALPHAT0KEN'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
            <a href='https://opensea.io/collection/sacred-shard'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
            <a href='https://t.me/+T0KeRpvqgAU5Yjgx'> <img className="footer__icon" src={tg} alt="Icon 1"/></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0x70235a346a1ec1d7a40181ff88a3a2e5260e1d04'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
            <a href='mailto:alpha.support@gmail.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
          </div>
          <div className="footer__section">
            $Alpha
          </div>
          <div className="footer__section">
            <p className="footer__copyright">© {new Date().getFullYear()} Copyrights & Protected</p>
          </div>
        </footer>
      </div>
      }
    </motion.section>
    { showModal &&
      <div className='modal-stake-overlay'>
        <div className='modal-stake-content'>
          <button className='close-button' onClick={closeModal}>X</button>
          <h2 className='modalstake-title-txt'>Enter amount to stake</h2>
          <input
            type="number"
            value={stakeAmount}
            max={stakeMax}
            min={1}
            onChange={handleStakeAmountChange}
            placeholder="Enter amount"
            className='stake-input'
          />
          <button onClick={handleStakeChain} className='modal-stake-button'>Confirm Stake</button>
        </div>
      </div>
    }

    { showUnstakeModal &&
      <div className='modal-stake-overlay'>
        <div className='modal-stake-content'>
          <button className='close-button' onClick={closeModal}>X</button>
          <h2 className='modalstake-title-txt'>Enter amount to unstake</h2>
          <input
            type="number"
            value={unstakeAmount}
            onChange={handleUnStakeAmountChange}
            placeholder="Enter amount"
            className='stake-input'
          />
          <button onClick={handleUnstakeChain} className='modal-stake-button'>Confirm Unstake</button>
        </div>
      </div>
    }

    { showOldUnstakeModal &&
      <div className='modal-stake-overlay'>
        <div className='modal-stake-content'>
          <button className='close-button' onClick={closeModal}>X</button>
          <h2 className='modalstake-title-txt'>Enter amount to unstake</h2>
          <input
            type="number"
            value={unstakeAmount}
            onChange={handleUnStakeAmountChange}
            placeholder="Enter amount"
            className='stake-input'
          />
          <button onClick={handleOldUnstake} className='modal-stake-button'>Confirm Unstake</button>
        </div>
      </div>
    }
    </>
  );
}
